import React, {useContext, useEffect} from 'react';
import CardComponent from "../components/Common/CardComponent";
import CourseInfoTable from "../components/Common/CourseInfoTable";
import { ViewContext } from '../../../pages/Syllabus';
import { SyllabusService } from '../../../services/SyllabusService';
import { useAuth } from 'react-oidc-context';
import {Syllabus} from "../../../interfaces/SyllabusInterface";
import { CourseService } from '../../../services/CourseService';
import { Course } from '../../../interfaces/CourseInterface';
import {Group} from "../../../interfaces/AuthentificationInterface";
import { toast } from 'react-toastify';

function GeneralView() {
    const auth = useAuth();
    let [syllabus, setSyllabus] = React.useState<Syllabus>({} as Syllabus);
    let [course, setCourse] = React.useState({} as Course);
    const { selectedSyllabusCourse, currentPromo, role } = useContext(ViewContext);
    const [studentPromotion] = React.useState<number>(auth.user?.profile["promotion"] as number);

    useEffect(() => {
        if (selectedSyllabusCourse === -1) return;
        const promotion =  role === Group.Student ? studentPromotion.toString() : currentPromo;
        SyllabusService.GetLastSyllabusByCourseId(auth, selectedSyllabusCourse.toString(), promotion).then((Syllabus) => {
            setSyllabus(Syllabus);
        }).catch(() => {
            toast.error("Aucun syllabus trouvé pour ce cours.");
        });
        CourseService.GetCourseById(auth, selectedSyllabusCourse).then((Course) => {
            setCourse(Course);
        });
    }, [selectedSyllabusCourse]);
    
    if (selectedSyllabusCourse === -1) return <></>;

    return (
        <div>
            <div className="h-100 d-flex flex-column my-2 p-2 justify-content-between white-background rounded bg-light align-items-center">
                <div className="d-flex flex-row justify-content-center align-items-center rounded bg-primary w-100 text-center text-white">
                    { course.courseName }
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center w-100 text-center my-2">
                    <div className="card-group h-100 w-100">
                        <div className="card">
                            <CourseInfoTable syllabus={syllabus} course={course}></CourseInfoTable>
                        </div>
                        <CardComponent label={"Prérequis"} content={syllabus.prerequisites}></CardComponent>
                        <CardComponent label={"Calcul de la note"} content={syllabus.finalGradeCalcul}></CardComponent>
                        <CardComponent label={"Acquis d'apprentissage"} content={syllabus.outcomes}></CardComponent>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center w-100 text-center my-2">
                    <div className="card-group h-100 w-100">
                        <CardComponent label={"Résumé et contexte"} content={syllabus.summary}></CardComponent>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center w-100 text-center my-2">
                    <div className="card-group h-100 w-100">
                        <CardComponent label={"Format d'apprentissage"} content={syllabus.learningFormat}></CardComponent>
                        <CardComponent label={"Plan du cours"} content={syllabus.plan}></CardComponent>
                        <CardComponent label={"Bibliographie"} content={syllabus.bibliography}></CardComponent>
                        <CardComponent label={"Evaluation des acquis d'apprentissage"} content={syllabus.aavs}></CardComponent>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GeneralView;
