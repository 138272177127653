import React, { useContext, useEffect, useState } from 'react';
import {SyllabusService} from "../../../../services/SyllabusService";
import {useAuth} from "react-oidc-context";
import { View, ViewContext } from '../../../../pages/Syllabus';
import { toast } from 'react-toastify';
import {Group} from "../../../../interfaces/AuthentificationInterface";
import {Syllabus} from "../../../../interfaces/SyllabusInterface";

function SyllabusFillFormView() {
    const auth = useAuth();
    const textLengthLimit = 5000;
    const { selectedSyllabusCourse, role, currentPromo, setCurrentView, setSelectedSyllabusCourse } = useContext(ViewContext);
    const [syllabusSnapshot, setSyllabusSnapshot] = useState<Syllabus>();
    const [formData, setFormData] = useState<{
        [key: string]: string;
    }>({
        totalHours: '',
        faceToFaceVolume: '',
        language: 'Français',
        prerequisites: '',
        summary: '',
        outcomes: '',
        aavs: '',
        plan: '',
        learningFormat: '',
        finalGradeCalcul: '',
        bibliography: '',
    });

    useEffect(() => {
        SyllabusService.GetLastSyllabusByCourseId(auth, selectedSyllabusCourse.toString(), currentPromo).then((Syllabus) => {
            setSyllabusSnapshot(Syllabus);
            setFormData({
                totalHours: Syllabus.totalHours?.toString() ?? '',
                faceToFaceVolume: Syllabus.faceToFaceVolume?.toString() ?? '',
                language: !(Syllabus.language) || Syllabus.language === "" ? 'Français' : Syllabus.language,
                prerequisites: Syllabus.prerequisites ?? '',
                summary: Syllabus.summary ?? '',
                outcomes: Syllabus.outcomes ?? '',
                aavs: Syllabus.aavs ?? '',
                plan: Syllabus.plan ?? '',
                learningFormat: Syllabus.learningFormat ?? '',
                finalGradeCalcul: Syllabus.finalGradeCalcul ?? '',
                bibliography: Syllabus.bibliography ?? '',
            });
        }).catch(() => {
            toast.error("Aucun syllabus trouvé pour ce cours.");
        });
    }, []);

    const findDifferences = (syllabus1: Syllabus, formData: { [p: string]: string }): string[]  => {
        let SyllabusDifference: string[] = [];
        for (const key in formData) {
            if ((syllabus1[key as keyof Syllabus] !== null) && (syllabus1[key as keyof Syllabus].toString() !== formData[key].toString())) {
                SyllabusDifference.push(key);
            }
        }
        return SyllabusDifference;
    };

    const CreateDifferenceMessage = ():  string => {
        if (formData === undefined || selectedSyllabusCourse === undefined || syllabusSnapshot === undefined) {
            return '';
        }
        const syllabusDifference = findDifferences(syllabusSnapshot, formData);
        if (syllabusDifference.length === 0) {
            return '';
        }
        if (syllabusDifference.length === 1) {
            return 'Le champ suivant a été modifié : ' + syllabusDifference[0] + '.';
        }
        let message = 'Les champs suivants ont été modifiés : ';
        for (let i = 0; i < syllabusDifference.length - 1; i++) {
            message += syllabusDifference[i] + ', ';
        }
        message += syllabusDifference[syllabusDifference.length - 1] + '.';
        return message;
    };

    const handleNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.toString() === '' || parseInt(event.target.value) >= 0)
        {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if ( formData.totalHours === '' || formData.faceToFaceVolume === '' || formData.prerequisites === '' || formData.summary === '' || formData.outcomes === '' || formData.aavs === '' || formData.plan === '' || formData.learningFormat === '' || formData.finalGradeCalcul === '' || formData.bibliography === '') {
            toast.error('Tous les champs doivent être remplis');
            return;
        }
        if (formData.totalHours !== '' && formData.faceToFaceVolume !== '') {
            if (parseInt(formData.totalHours) < parseInt(formData.faceToFaceVolume)) {
                toast.error('Le volume horaire total ne peut pas être inférieur au volume horaire face à face');
                return;
            }
        }
        try {
            SyllabusService.UpdateSyllabusInfos(
                auth,
                selectedSyllabusCourse.toString(),
                Number.parseInt(formData.totalHours),
                Number.parseInt(formData.faceToFaceVolume),
                formData.language,
                formData.prerequisites,
                formData.summary,
                formData.outcomes,
                formData.plan,
                formData.aavs,
                formData.learningFormat,
                formData.finalGradeCalcul,
                formData.bibliography,
                currentPromo,
                role === Group.MajorLeader ? CreateDifferenceMessage() : '',
            ).then(() => {
                toast.success('Syllabus modifié avec succès');
                setSelectedSyllabusCourse(-1); // Unselect syllabus
                setCurrentView(View.SyllabusTableView);
            });
        }
        catch (error) {
            toast.error('Erreur lors de la modification du syllabus');
        }
    };

    const formNumberInputFields = [
        { name: 'totalHours', label: 'Volume horaire total', type: 'number' },
        { name: 'faceToFaceVolume', label: 'Volume horaire face à face', type: 'number' },
    ];

    const formTextAreaFields = [
        { name: 'prerequisites', label: 'Prérequis', type: 'text' },
        { name: 'summary', label: 'Résumé du cours', type: 'text' },
        { name: 'outcomes', label: 'Acquis d\'apprentissage visés (AAVs)', type: 'text' },
        { name: 'aavs', label: 'Evaluation des AAVs', type: 'text' },
        { name: 'plan', label: 'Plan', type: 'text' },
        { name: 'learningFormat', label: 'Format d\'apprentissage', type: 'text' },
        { name: 'finalGradeCalcul', label: 'Calcul de la note finale', type: 'text' },
        { name: 'bibliography', label: 'Bibliographie', type: 'text' },
    ];

    return (
        <div className="h-100 w-100 d-flex flex-column my-2 p-2 justify-content-between white-background rounded bg-light align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center bg-primary rounded w-100 text-center text-white">
                Fomulaire de modification de syllabus
            </div>
            <br/>
            <form className="w-100" onSubmit={handleSubmit}>
                {formNumberInputFields.map((field) => (
                    <div className="form-group mb-2" key={field.name}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <input
                            type={field.type}
                            className="form-control"
                            id={field.name}
                            name={field.name}
                            value={formData[field.name]}
                            onChange={handleNumberInputChange}
                        />
                    </div>
                ))}
                <div className="form-group mb-2">
                    <label htmlFor="language">Langue</label>
                    <select
                        className="form-control"
                        id="language"
                        name="language"
                        value={formData.language}
                        onChange={handleSelectChange}
                    >
                        <option value="Français">Français</option>
                        <option value="Anglais">Anglais</option>
                        <option value="Hybride">Hybride</option>
                    </select>
                </div>
                {formTextAreaFields.map((field) => (
                    <div className="form-group mb-2" key={field.name}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <textarea
                            className="form-control"
                            id={field.name}
                            name={field.name}
                            value={formData[field.name]}
                            onChange={handleTextAreaChange}
                            style={{ height: '100px', resize: 'none' }}
                            maxLength={textLengthLimit}
                        />
                        <div style={{ color: textLengthLimit - formData[field.name].length < 50 ? 'red' : 'gray', fontSize: '10px', marginTop: '4px' }}>
                            {textLengthLimit - formData[field.name].length} / {textLengthLimit}
                        </div>
                    </div>
                ))}
                <br/>
                <button type="submit" className="btn btn-primary">
                    Soumettre
                </button>
            </form>
        </div>
    );
}

export default SyllabusFillFormView;
