import React, { useState } from 'react';
import { Outlet } from "react-router-dom";
import TooltipManager from "../components/sidebar/ToolTipManager";
import Sidebar from "../components/sidebar/Sidebar";
import { Button } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';

const Layout = () => {
    const auth = useAuth();
    const [screensize, setScreensize] = useState<'Small' | 'Big'>(window.innerWidth > 768 ? 'Big' : 'Small');

    // When screen size is small, change the column size
    useState(() => {
        window.addEventListener('resize', () => {
            setScreensize(window.innerWidth > 768 ? 'Big' : 'Small');
        });
    });

    return (
        <>
            {screensize === 'Big' ? (
                <>
                    <div>
                        <Sidebar />
                        <TooltipManager />
                    </div>
                    <div className="page-content">
                        <Outlet />
                    </div>
                </>
            ) : (
                <>
                    <div className="mobile-content">
                        <Outlet />
                        <Button variant="danger" className="mt-2" style={{ width: '100%' }} onClick={() => { auth.signoutRedirect(); }}>Déconnexion</Button>
                    </div>
                </>
            )}
        </>
    );
};

export default Layout;
