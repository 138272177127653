export enum SyllabusState {
    filled = 'Complété',
    validated = 'Validé',
    toEdit = 'À compléter',
}

export type Syllabus = {
    syllabusId: number
    courseId: string
    promotion: string
    ueCoef: number
    totalHours: number
    faceToFaceVolume: number
    language: string
    prerequisites: string
    summary: string
    outcomes: string
    aavs: string
    plan: string
    learningFormat: string
    finalGradeCalcul: string
    bibliography: string
    status: SyllabusState
    lastModificationDate: string
    visibility: boolean
}
