import React, { useState, useEffect } from "react";
import axios from "axios";
import { FiltersInterface } from "../../../interfaces/AnnuaireInterface";
import "./styles/DataTable.css";

interface DataTableProps {
    filters: FiltersInterface;
}

interface DataItem {
    fullName: string;
    profileUrl: string;
    criProfileUrl: string;
    emailEpita: string;
    promotionSIGL: string;
    loginEpita: string;
    firstName: string;
    lastName: string;
    profileImageUrl: string;
    additionalInfo: string;
    job: string;
    location: string;
    company: string;
}

const DataTable: React.FC<DataTableProps> = ({ filters }) => {
    const [data, setData] = useState<DataItem[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const itemsPerPage = 10;

    useEffect(() => {
        setCurrentPage(1);
    }, [filters]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://annuaire.sigl.epita.fr/api/sigls');
                console.log("Data fetched:", response.data);
                setData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    console.log("Data", data);
    const filteredData = data?.filter((item) => {
        return (
            (!filters.fullName || item.fullName?.toLowerCase().includes(filters.fullName.toLowerCase())) &&
            (!filters.job || item.job?.toLowerCase().includes(filters.job.toLowerCase())) &&
            (!filters.location || item.location?.toLowerCase().includes(filters.location.toLowerCase())) &&
            (!filters.company || item.company?.toLowerCase().includes(filters.company.toLowerCase())) &&
            (!filters.promotionSIGL || item.promotionSIGL?.toString().includes(filters.promotionSIGL))
        );
    });

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const displayedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="page-body">
            <table className="data-table">
                <thead>
                    <tr>
                        <th style={{ width: "20%" }}>Nom</th>
                        <th style={{ width: "20%" }}>Métier</th>
                        <th style={{ width: "20%" }}>Pays</th>
                        <th style={{ width: "20%" }}>Entreprise</th>
                        <th style={{ width: "20%" }}>Promotion</th>
                    </tr>
                </thead>
                <tbody>
                    {displayedData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.fullName}</td>
                            <td>{item.job}</td>
                            <td>{item.location}</td>
                            <td>{item.company}</td>
                            <td>{item.promotionSIGL}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="footer">
                <p>Total: {totalItems}</p>
                <div className="pagination">
                    <span>{currentPage}/{totalPages}</span>
                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
                </div>
            </div>
        </div>
    );
};

export default DataTable;
