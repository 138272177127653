import React from "react";
import {Table} from "react-bootstrap";
import { Syllabus } from "../../../../interfaces/SyllabusInterface";
import { Course } from "../../../../interfaces/CourseInterface";

function CourseInfoTable(props : { syllabus : Syllabus, course : Course }) {
    return (
        <Table className="w-100 h-100" striped bordered hover>
            <tbody>
                <tr>
                    <td>Nom</td>
                    <td>{ props.course.courseName }</td>
                </tr>
                <tr>
                    <td>Semestre</td>
                    <td>{ props.course.semester }</td>
                </tr>
                <tr>
                    <td>Coefficient UE</td>
                    <td>{ props.syllabus.ueCoef }</td>
                </tr>
                <tr>
                    <td>Volume Horaire Total</td>
                    <td>{ props.syllabus.totalHours }</td>
                </tr>
                <tr>
                    <td>Volume Face à Face</td>
                    <td>{ props.syllabus.faceToFaceVolume }</td>
                </tr>
                <tr>
                    <td>Langue</td>
                    <td>{ props.syllabus.language }</td>
                </tr>
                <tr>
                    <td>Référent</td>
                    <td>{ props.course.teachers?.at(0)?.teacherLogin ?? '' }</td>
                </tr>
            </tbody>
        </Table>
    );
}

export default CourseInfoTable;
